<template>
<div>
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="mini"
             :disabled="type === 'detail'" label-position="top">
    <el-form-item label="制度名称" prop="name">
      <el-input v-model="dataForm.name" placeholder="制度名称" style="width: 300px;"></el-input>
    </el-form-item>
    <el-form-item label="发布部门" prop="releaseOrg">
      <el-input v-model="dataForm.releaseOrg" placeholder="发布部门" style="width: 300px;"></el-input>
    </el-form-item>
        <el-form-item :label="'发布时间'" prop="releaseTime">
        <el-date-picker
            v-model="dataForm.releaseTime"
            style="width: 300px"
            type="date"
            :placeholder="'请选择时间'"
            value-format="yyyy-MM-dd"
        ></el-date-picker>
        </el-form-item>
        <el-form-item label="上传文档" prop="ossId">
            <el-image
                v-if="uploadRes"
                @click="showPdf(uploadRes)"
                style="width: 300px; height: 200px"
                :src="showImgUrl"
                fit="contain"
            ></el-image>
            <el-upload
                :headers="uploadHeaders"
                :show-file-list="false"
                :action="uploadUrl"
                drag
                multiple
                accept="application/pdf"
                :before-upload="beforeUpload"
                :on-success="uploadCallback"
                :auto-upload="true">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {previewPdf} from '@/utils';
export default {

    props: {
        id: {
            type: Number,
            default: null,
        },
        type: {
            type: String,
            default: 'add',
        },
    },
    data() {
        return {
            uploadHeaders: {
                token: this.$store.state.token
            },
            showImgUrl: '',
            uploadUrl: this.axios.defaults.baseURL + '/files',
            baseServer: this.axios.defaults.baseURL,
            visible: false,
            dataForm: {
                categoryId: '',
                name: '',
                ossId: '',
                remark: '',
                releaseTime: '',
                releaseOrg: '',
            },
            uploadRes: null,
            dataRule: {
                name: [
                    {required: true, message: '文档名称不能为空', trigger: 'blur'}
                ],
                releaseTime: [
                    {required: true, message: '发布时间不能为空', trigger: 'blur'}
                ],
                releaseOrg: [
                    {required: true, message: '发布部门不能为空', trigger: 'blur'}
                ],
                ossId: [
                    {required: true, message: '请上传手册', trigger: 'blur'}
                ]
            }
        };
    },
    methods: {
        beforeUpload(file) {
            const isPDF = file.type.indexOf('pdf') > -1;
            const isLt2M = file.size / 1024 / 1024 < 20;

            if (!isPDF) {
                this.$message.error('只能上传PDF格式文件!');
                return false;
            }
            if (!isLt2M) {
                this.$message.error('文件大小不能超过20MB!');
                return false;
            }
            this.uploadLoading = this.$loading({
                lock: true,
                text: '请稍候',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        uploadCallback(res) {
            this.uploadLoading.close();
            this.uploadRes = res.data;
            this.showImgUrl = this.uploadUrl + '/' + this.uploadRes.id + '/thumbnail?token=' + this.uploadHeaders.token;
            this.dataForm.ossId = res.data.id;
        },
        showPdf(item) {
            previewPdf(
                this.uploadUrl + '/' + item.id + '?token=' + this.uploadHeaders.token,
                this.dataForm.name
            );
        },
        init() {
            this.visible = true;
            this.$nextTick(() => {
                this.$refs.dataForm.resetFields();
                this.uploadRes = null;
            });
        },
        // 表单提交
        submitHandle(callback) {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    this.$client.saveHandbook({
                        categoryId: this.dataForm.categoryId,
                        name: this.dataForm.name,
                        ossId: this.dataForm.ossId,
                        remark: this.dataForm.remark,
                        releaseTime: this.dataForm.releaseTime,
                        releaseOrg: this.dataForm.releaseOrg,
                    }).then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500
                        });
                        callback();
                    });
                }
            });
        }
    }
};
</script>
